import '../stylesheets/common'

import RailsUJS from '@rails/ujs'

RailsUJS.start()

import * as Sentry from '@sentry/browser'

window.Sentry = Sentry

// import { Turbo } from '@hotwired/turbo-rails'
import '@hotwired/turbo-rails'
Turbo.session.drive = false

// remove as soon as https://github.com/hotwired/turbo/issues/540 will be fixed
const adapter = Turbo.navigator.delegate.adapter

document.addEventListener('turbo:before-fetch-request', function (event) {
  const target = event.target
  if (!(target instanceof HTMLElement)) return

  if (target.getAttribute('data-turbo-action') === 'advance') {
    adapter.formSubmissionStarted(this)
  }
})

document.addEventListener('turbo:before-fetch-response', function (event) {
  adapter.formSubmissionFinished(this)
})
